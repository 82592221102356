<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <DataTables ref="dataTable" v-bind:title="title" v-bind:tableData="tableData" v-bind:fields="fields"
                    v-on:loadItems="loadItems" :search="search">
                    <template v-slot:headerbar>
                        <div style="padding-left:20px">
                            <b-form-radio-group v-model="selectedStatus" button-variant="outline-primary" size="sm"
                                name="radio-btn-outline" @change="statusChange" buttons />
                        </div>
                    </template>
                </DataTables>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            title: "All Claim",
            loading: false,
            fields: [
                { key: 'user_id', label: "SH ID", sortable: true },
                { key: 'nama_tertanggung', label: "PARTICIPANT NAME", sortable: true },
                { key: 'cashless', label: "METHOD", sortable: true},
                { key: 'requester_product_type', label: "PRODUCT TYPE", sortable: true },
                { key: 'policy.certificate_no', label: "CERTIFICATE NO", sortable: true },
                { key: 'claim_no', label: "CLAIM TICKET NO", sortable: true },
                { key: 'claim_total_amount', label: "CLAIM AMOUNT", sortable: true },
                { key: 'covered_total_amount', label: "APPROVED AMOUNT", sortable: true },
                { key: 'request_claim_date', label: "REQUEST CLAIM DATE", sortable: true },
                { key: 'claim_status', label: "CLAIM STATUS", sortable: true },
                { key: 'dob_tertanggung', label: "PARTICIPANT DOB", sortable: true },
                { key: 'created_at', label: "CREATED AT", sortable: true },
                { key: 'updated_at', label: "UPDATED AT", sortable: true },
            ],
            selectData: {
                page: 1,
                pages: 1,
                total: 0,
                limit: 10,
                docs: [],
            },
            tableData: {
                page: 1,
                pages: 1,
                total: 0,
                limit: 10,
                docs: [],
            },
            currentData: {},
            search: "",
        }
    },
    async created() {
        var page = this.$route.query.p;
        var limit = this.$route.query.l;
        var search = this.$route.query.s;

        this.loadItems(page ? page : 1, limit ? limit : 10, search ? search : "");
    },
    methods: {
        async loadItems(page, limit, search) {
            let localCoverage = JSON.parse(localStorage.getItem("coverage"));

            if (localCoverage !== null && localCoverage !== undefined) {
                var coverageId = localCoverage.id;
            } else {
                this.$router.push('/select-coverage');
            }

            this.search = search;
            this.$router
                .replace({ query: { ...this.$route.query, p: page, l: limit, s: search } })
                .catch(() => { });

            this.$emit('showLoading', true);
            var response = await this.$apiController('get', `/human_resource/all_claim/${coverageId}?page=${page}&perpage=${limit}&searchquery=${search}`);

            this.$emit('showLoading', false);
            if (response.is_ok) {
                this.tableData = {
                    ...response.data,
                    docs: response.data.docs.map(item => {

                        return {
                            ...item,
                            requester_product_type: item.requester_product_type === '1' ? 'SALVUSCARE' :
                                item.requester_product_type === '2' ? 'INSURANCE CLAIM' :
                                    item.requester_product_type,
                            dob_tertanggung: moment(item.policy.dob_tertanggung).format('DD/MM/YYYY'),
                            request_claim_date :  moment(item.request_claim_date).format('DD/MM/YYYY HH:mm:ss'),
                            created_at: moment(item.created_at).format('DD/MM/YYYY HH:mm:ss'),
                            updated_at: moment(item.updated_at).format('DD/MM/YYYY HH:mm:ss'),
                            cashless: item.cashless === true ? 'CASHLESS' : 'REIMBURSE',
                        };
                    })
                };
                console.log('aa', this.tableData);
            } else {
                this.$emit('showAlert', response.message, "danger");
            }
        },
    }
}
</script>

<style></style>